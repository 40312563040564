import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src311988156/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "madup-incmad-design"
    }}>{`@madup-inc/mad-design`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Design systenm of LEVER`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://github.com/madup-inc/mad-design/packages/826184"
      }}><img alt="NPM" src="https://img.shields.io/npm/v/crl-docz.svg" /></a>{` `}<a parentName="p" {...{
        "href": "https://standardjs.com"
      }}><img alt="JavaScript Style Guide" src="https://img.shields.io/badge/code_style-standard-brightgreen.svg" /></a></p>
    <h1 {...{
      "id": "디자인시스템-사용"
    }}>{`디자인시스템 사용`}</h1>
    <h2 {...{
      "id": "설치"
    }}>{`설치`}</h2>
    <p><em parentName="p">{`설치하려면 프로젝트 루트에 .npmrc 권한 파일 필요`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add @madup-inc/mad-design
`}</code></pre>
    <h2 {...{
      "id": "사용방법"
    }}>{`사용방법`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import {Button} from '@madup-inc/mad-design'
import '@madup-inc/mad-design/dist/index.css'

export default () => {
  return (
    <Button size="large">확인</Button>
  )
}
`}</code></pre>
    <br />
    <h1 {...{
      "id": "디자인시스템-개발"
    }}>{`디자인시스템 개발`}</h1>
    <h2 {...{
      "id": "프로젝트-구성"
    }}>{`프로젝트 구성`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.npmjs.com/package/create-react-library"
      }}>{`creat-react-library`}</a>{` 와 `}<a parentName="p" {...{
        "href": "https://www.docz.site/"
      }}>{`docz`}</a>{` 프로젝트를 모노레포로 구성함`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`path`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`desc`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`command`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`/packages/components`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`컴포넌트 개발`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yarn dev:components`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`/packages/docs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`문서 개발`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yarn dev:docs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`/packages/example`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`컴포넌트 테스트`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yarn dev:example`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "의존성-설치"
    }}>{`의존성 설치`}</h2>
    <p>{`루트 프로젝트에서 한번만 의존성 설치를 수행합니다`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn
`}</code></pre>
    <h2 {...{
      "id": "컴포넌트-빌드"
    }}>{`컴포넌트 빌드`}</h2>
    <pre><code parentName="pre" {...{}}>{`yarn build:components
`}</code></pre>
    <p>{`코드 변경시 자동빌드`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn watch:components
`}</code></pre>
    <h2 {...{
      "id": "개발문서-시작"
    }}>{`개발문서 시작`}</h2>
    <pre><code parentName="pre" {...{}}>{`yarn dev:docs
`}</code></pre>
    <p>{`로컬 개발문서 서버 접속)
http://localhost:15000`}</p>
    <blockquote>
      <p parentName="blockquote">{`docz 를 시작하기 전에 컴포넌트 빌드 필수.`}</p>
      <p parentName="blockquote">{`컴포넌트 변경사항이 자동으로 문서에 적용되도록 하려면, 2개의 터미널을 열고 한쪽에서는 `}<inlineCode parentName="p">{`yarn watch:components`}</inlineCode>{` , 다른 한쪽에서는 `}<inlineCode parentName="p">{`yarn dev:docs`}</inlineCode>{` 를 실행합니다.`}</p>
      <p parentName="blockquote">{`Note1) 위 개발문서 서버의 포트번호를 변경할 경우 오류가 발생할 수 있습니다. `}<a parentName="p" {...{
          "href": "https://github.com/madup-inc/mad-design/issues/4#issuecomment-858289378"
        }}>{`https://github.com/madup-inc/mad-design/issues/4#issuecomment-858289378`}</a></p>
    </blockquote>
    <br />
    <h2 {...{
      "id": "배포"
    }}>{`배포`}</h2>
    <h3 {...{
      "id": "develop-브랜치"
    }}>{`develop 브랜치`}</h3>
    <p>{`develop 브랜치는 upstream 으로 배포시에 dev.design.lever.me 문서만 업데이트 됩니다.`}</p>
    <h3 {...{
      "id": "main-브랜치"
    }}>{`main 브랜치`}</h3>
    <p>{`main 브랜치는 upstream 으로 배포 후, `}<strong parentName="p">{`새로운 태그를 생성`}</strong>{`해야 배포 프로세스가 수행됩니다.`}</p>
    <blockquote>
      <p parentName="blockquote">{`신규 태그 생성 전에 packages/components/package.json 의 버젼과 태그의 버젼을 일치시켜 주세요.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      